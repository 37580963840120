import React, { useContext, useEffect, useState } from 'react'
import { get, put } from "../../helper/fetch";
import { DrawerContext } from "../../context/DrawerContext";
import { toast } from 'react-toastify';
import { Cleaner } from "../../settings/bookingTypes";
import CleanerReviewCard from "../../components/CleanerReviewCard/CleanerReviewCard";
import CleanerMap from "../../components/CleanerMap/CleanerMap";
import user from "../../image/user.jpg";
import AreaMap from "../AreaMap/AreaMap";
type Review = Cleaner['listing']['reviews'][number];

export default function CleanerProfile() {
  const [cleaner, setCleaner] = useState({} as Cleaner);
  const [editReviews, setEditReviews] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);
  const reviewsRef = React.useRef(null);
  const [reviewsPage, setReviewsPage] = useState(1);

  useEffect(() => {
    get(`/me/listing`)
      .then(r => {
        setCleaner(r);
      }).catch(e => {
      })
  }, []);

  function getPaginatedReviews(reviews: Review[], pageNumber: number): Review[] {
    const pageSize = 5;
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return reviews.slice(startIndex, endIndex);
  }

  const updateCleaner = async (data) => {
    const resp = await put(`/me/listing`, data);
    if (resp.status !== 200) {
      toast.error('could not update your info')
    } else {
      toast.success('updated your info')
      //refetch
      get(`/me/listing`)
        .then(r => {
          setCleaner(r);
        }).catch(e => {
        })
      dispatch({ type: "CLOSE_DRAWER" })
    }
  }

  async function updateCleanerMap(newCoords) {
    const resp = await put(`/me/map`, newCoords);
    if (resp.status !== 200) {
      toast.error('could not update map')
    } else {
      toast.success('updated map')
    }
  }

  return (
    <div className="relative min-h-screen bg-gray-100">
      <main className="py-10">
        <div
          className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src={`https://homespritz-cleaner-avatars.s3.ca-central-1.amazonaws.com/${cleaner.listing?.id}.jpg`}
                  alt=""
                />
                <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{cleaner.name}</h1>
              <h2 className="text-1xl font-bold text-green-900">${cleaner.listing?.price} </h2>
              <p className="text-sm font-medium text-gray-500">
                joined on <time dateTime="2020-08-25">{cleaner.joined}</time>
              </p>
              {
                cleaner.listing?.open
                  ? <h2 className="text-1xl font-bold text-green-600">Open for bookings!</h2>
                  : <h2 className="text-1xl font-bold text-red-700"> Closed to booking requests</h2>
              }
            </div>
          </div>
          <div
            className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              onClick={() => dispatch({
                type: "OPEN_DRAWER",
                drawerComponent: "EDIT_CLEANER_FORM",
                data: {
                  id: 'self',
                  open: cleaner.listing.open,
                  price: cleaner.listing.price,
                  listingTitle: cleaner.listing.displayName,
                  given_name: cleaner.given_name,
                  family_name: cleaner.family_name,
                  stripeAccount: cleaner.connectedAccount,
                  phoneNumber: cleaner.phoneNumber,
                  email: cleaner.email,
                  description: cleaner.listing.description,
                  tier: cleaner.listing.tier,
                  onSubmit: (data) => {
                    updateCleaner(data);
                  }
                }
              })}
            >
              Edit My Listing
            </button>
          </div>
        </div>

        <div
          className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Listing display name: {cleaner.listing?.displayName}
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">City Works in</dt>
                      <dd className="mt-1 text-sm text-gray-900">Calgary</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Email address</dt>
                      <dd className="mt-1 text-sm text-gray-900">{cleaner.email}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Cleanings completed</dt>
                      <dd className="mt-1 text-sm text-gray-900">missing info</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Phone</dt>
                      <dd className="mt-1 text-sm text-gray-900">{cleaner.phoneNumber}</dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">About</dt>
                      <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap whitespace-pre">
                        {cleaner.listing?.description}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <p className="text-sm font-medium text-gray-800">Combined Ratings</p>
                      {editReviews
                        ? <div>
                        </div>
                        : <>
                          <div className={"pb-6"}>
                            <CleanerReviewCard
                              reviewItems={[
                                { title: 'Quality', value: cleaner.listing?.ratings?.quality || 4 },
                                { title: 'Reliability', value: cleaner.listing?.ratings?.reliability || 4 },
                                { title: 'Communication', value: cleaner.listing?.ratings?.communication || 4 },
                              ]}
                            />
                          </div>
                        </>
                      }
                    </div>
                    <div className="sm:col-span-2">
                      <p className="text-base font-medium text-red-600">Number of
                        complaints: {cleaner.listing?.complaints_count || 0}</p>
                      <p className="text-base font-medium text-green-500">Number of
                        reviews: {cleaner.listing?.reviews?.length || 0} </p>
                      <p
                        className="text-sm font-medium text-blue-400 hover:cursor-pointer hover:underline hover:text-blue-600"
                        onClick={() => reviewsRef.current.scrollIntoView({ behavior: 'smooth' })}>(view reviews)</p>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Map
                    </h2>
                  </div>
                  <div className="h-96 px-4 py-6 sm:px-6" style={{ marginBottom: '40px' }}>
                    {/*<AreaMap />*/}
                    <CleanerMap
                      north={cleaner.listing?.coordinates.north}
                      south={cleaner.listing?.coordinates.south}
                      east={cleaner.listing?.coordinates.east}
                      west={cleaner.listing?.coordinates.west}
                      onSave={(newCoords) => {
                        updateCleanerMap(newCoords)
                      }}
                    />
                  </div>
                </div>

              </div>
            </section>

            <section aria-labelledby="notes-title" ref={reviewsRef}>
              <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Reviews
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6" style={{ marginBottom: '40px' }}>

                    <ul role="list" className="divide-y divide-gray-100">
                      {reviewsPage && getPaginatedReviews(cleaner.listing?.reviews || [], reviewsPage).map((review, idx) => (
                        <li
                          key={`review-${idx}`}
                          className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
                        >
                          <div>
                            <div className="flex flex-row justify-between">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                {review.text}
                              </p>


                            </div>

                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">

                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          <span className="font-medium">{cleaner.listing?.reviews?.length || 0}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <p
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                        >{reviewsPage}</p>
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={() => {
                            if (reviewsPage > 1) {
                              setReviewsPage(reviewsPage - 1);
                            }
                          }}
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={() => {
                            if (reviewsPage < Math.ceil((cleaner.listing?.reviews?.length || 0) / 10)) {
                              setReviewsPage(reviewsPage + 1);
                            }
                          }}
                        >
                          Next
                        </a>
                      </div>
                    </nav>
                  </div>

                </div>

              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  )
}