import React from 'react';
import requested from "../requested.gif";
import succes from "../success.svg";

type BookingStatusProps = {
  children?: React.ReactNode;
  style?: any;
  status: string;
};

const getStatus = (status: string) => {
  switch (status.toLowerCase()){
    case 'requested':
      return (
          <>
            <img src={requested} alt="Requested" style={{height: '50px', marginLeft: '-10px'}}/>
            <div style={{
              height: '50px',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <h3 style={{fontSize: '16px'}}>Requested</h3>
            </div>
          </>
      );
    case 'accepted':
      return (
          <>
            <img src={succes} alt="Accepted" style={{height: '50px', marginLeft: '-10px'}}/>
            <div style={{
              height: '50px',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <h3 style={{fontSize: '16px'}}>Accepted</h3>
            </div>
          </>
      )
    case 'cancelled':
      return (
          <>
            <div style={{
              height: '50px',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <h3 style={{fontSize: '16px'}}>Cancelled</h3>
            </div>
          </>
      )
    case 'rejected':
      return (
          <>
            <div style={{
              height: '50px',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <h3 style={{fontSize: '16px'}}>Rejected</h3>
            </div>
          </>
      )
    case 'paid':
      return (
          <>
            <div style={{
              height: '50px',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <h3 style={{fontSize: '16px'}}>Paid</h3>
            </div>
          </>
      )
    default:

  }
}

const BookingStatus = (props: BookingStatusProps) => {
  return (
      <div style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "start"
      }}>
        {props && props.status && getStatus(props.status)}
      </div>
  )
};

export default BookingStatus;