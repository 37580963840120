import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import { setCookie } from './session';
import { post } from './fetch';
import firebaseConfig from "../firebase.config";

class Firebase {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth();
    this.messaging = getMessaging(this.app);
  }

  getMessaging() {
    return this.messaging;
  }

  async login(email, password) {
    try {
      const firebaseAuth = await signInWithEmailAndPassword(this.auth, email, password);
      const token = await firebaseAuth.user.getIdToken();
      const homespritzAuth = await post('/me/auth', { idToken: token });
      if (homespritzAuth.status !== 200) {
        return Promise.resolve(false);
      } else {
        const body = await homespritzAuth.json();
        setCookie('access_token', body.authToken);
        await this.auth.signOut();
        return Promise.resolve(true);
      }
    } catch (e) {
      return Promise.resolve(false);
    }
  }

  async changePassword(email) {
    try {
      const resp = await sendPasswordResetEmail(this.auth, email);
    } catch (e) {}
  }

  logout() {}
}

export default new Firebase();
