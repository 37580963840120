import React, { useContext, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  RESETPASSWORD,
  BOOKINGS,
  LISTING,
  BOOKING,
  NOTIFICATIONS,
  SCHEDULE,
  MONEY,
  BOOKING_OFFER,
} from "./settings/constants";
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import Bookings from "./containers/Bookings/Bookings";
import BookingOffer from "./containers/BookingOffer/BookingOffer";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Booking from "./containers/Booking/Booking";
import CleanerProfile from "./containers/CleanerProfile/CleanerProfile";
import NotificationsPage from "./containers/NotificationsPage/NotificationsPage";
import TimeOff from "./containers/TimeOff/TimeOff";
import MoneyPage from "./containers/MoneyPage/MoneyPage";
import BookingOfferList from "./containers/BookingOffer/BookingOffers";
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));

const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));


/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? (children)
          : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          {/* New public route for booking offer */}
          <PrivateRoute path={`/o/:offerId`}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BookingOffer />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          {/* Existing routes */}
          <PrivateRoute exact={true} path={"/"}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Bookings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={BOOKING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Booking />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={BOOKING_OFFER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BookingOfferList />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>



          <PrivateRoute exact path={BOOKINGS} >
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Bookings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LISTING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CleanerProfile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SCHEDULE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TimeOff />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={NOTIFICATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <NotificationsPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MONEY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MoneyPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={RESETPASSWORD}>
            <ResetPassword />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
