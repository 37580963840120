import React from "react";
import {useHistory} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Error, FormFields, FormLabel, FormTitle} from "../../components/FormFields/FormFields";
import {FormWrapper, LogoImage, LogoWrapper, Wrapper} from "./ResetPassword.style";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Logoimage from "../../image/logo-hs.png";
import firebase from "../../helper/firebase";

const initialValues = {
  email: "",
};

const getLoginValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required("Email is Required!"),
  });
};

const MyInput = ({field, form, ...props}) => {
  return <Input {...field} {...props} />;
};

export default () => {
  let history = useHistory();

  let resetPassword = async ({email}) => {
    firebase.changePassword(email)
      .then(result => {
        history.replace('/login')
      }).catch(e => {})
  };

  return (
    <Wrapper>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={resetPassword}
          render={({errors, status, touched, isSubmitting}) => (
            <Form >
              <FormFields>
                <LogoWrapper>
                  <LogoImage src={Logoimage} alt="homespritz-cleaner-portal"/>
                </LogoWrapper>
                <FormTitle>Reset password for cleaner portal</FormTitle>
              </FormFields>

              <FormFields>
                <FormLabel>Email</FormLabel>
                <Field
                  type="email"
                  name="email"
                  component={MyInput}
                  placeholder="Ex: demo@demo.com"
                />
                {errors.email && touched.email && (
                  <Error>{errors.email}</Error>
                )}
              </FormFields>
              <Button
                type="submit"
                disabled={isSubmitting}
                overrides={{
                  BaseButton: {
                    style: ({$theme}) => ({
                      width: "100%",
                      marginLeft: "auto",
                      borderTopLeftRadius: "3px",
                      borderTopRightRadius: "3px",
                      borderBottomLeftRadius: "3px",
                      borderBottomRightRadius: "3px"
                    })
                  }
                }}
              >
                Submit
              </Button>
            </Form>
          )}
          validationSchema={getLoginValidationSchema}
        />
      </FormWrapper>
    </Wrapper>
  );
};
