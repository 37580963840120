import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {BaseProvider} from 'baseui';
import {theme} from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import firebase from './helper/firebase';
import { getToken } from "firebase/messaging";
import './theme/global.css';
import './index.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {post} from "./helper/fetch";
import {loadConnectAndInitialize} from "@stripe/connect-js";
import {ConnectComponentsProvider} from "@stripe/react-connect-js"; // theme css file
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

async function requestPermission() {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(firebase.getMessaging(), {
      vapidKey: "BKaWnL__9BqT40QfG_5bAwu5uupKO3qRC-vyLU2pDnrpsUpVoHl9g9uvMGR7Vxkhw1qyuBYeKWR9hj5LSMzo3m0",
    });

    //We can send token to server
    console.log("Token generated : ", token);
  } else if (permission === "denied") {
    //notifications are blocked
  }
}

function App() {
  const engine = new Styletron();
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await post('/me/stripe/account_session', {});
      if (!response.ok) {
        // Handle errors on the client side here
        const {error} = await response.json();
        console.error('An error occurred: ', error);
        // document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const {client_secret: clientSecret} = await response.json();
        // document.querySelector('#error').setAttribute('hidden', '');
        return clientSecret;
      }
    }

    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don’t submit any personally identifiable information in requests made with this key.
      publishableKey: publishableKey,
      fetchClientSecret: fetchClientSecret,
    })
  });
  useEffect(() => {
    requestPermission();
  }, []);
  return (
    <>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <StyletronProvider value={engine}>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <BaseProvider theme={theme}>
            <BrowserRouter>
              <Routes/>
            </BrowserRouter>
          </BaseProvider>
        </StyletronProvider>
      </ConnectComponentsProvider>

    </>
  );
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
